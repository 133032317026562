import { useQuery } from "~hooks/useQuery";
import {
  EventAccessStatus,
  GetEventAndReleaseForCustomerDocument,
} from "~graphql/typed-document-nodes";
import { showToast } from "~lib";
import { useRouter } from "next/router";
import { ToastType } from "react-toastify";
import * as actions from "~features/nonseated-reservation/store/actions";
import { useSDK } from "./useSDK";
import { GetEventAndReleaseForCustomerQueryVariables } from "~graphql/sdk";

type QueryParams = {
  eventId?: string;
  release?: string;
  slug?: string;
  code?: string;
};

export const useGetEventWithAccessControl = (userEventId?: string) => {
  const router = useRouter();
  const sdk = useSDK();
  const query = router.query as QueryParams;

  const eventId = query.eventId ?? userEventId;
  const releaseId = query.release;

  const { isLoading, data, error, isValidating } = useQuery(
    eventId ? GetEventAndReleaseForCustomerDocument : null,
    {
      input: { eventId, releaseId, releaseSlug: query.slug, code: query.code },
    },
    {
      fetcher: async (input: GetEventAndReleaseForCustomerQueryVariables) => {
        const res = await sdk.getEventAndReleaseForCustomer(input);
        const { redirect, accessType } = res.getEventAndReleaseForCustomer;

        if (
          accessType === EventAccessStatus.Denied &&
          !router.pathname.includes("/registration")
        ) {
          void showToast(
            "This event is not public. Admin only access.",
            ToastType.ERROR
          );
          await router.replace("/");
          return Promise.reject();
        } else if (accessType === EventAccessStatus.ShouldRedirect) {
          const { slug, code, eventId } = redirect;

          const query = {
            ...router.query,
            eventId,
            ...(slug && { slug }),
            ...(code && { code }),
          };

          await router.replace({
            pathname: router.pathname,
            query,
          });
          return Promise.reject();
        } else if (accessType === EventAccessStatus.EventEnded) {
          void showToast("This event has finished.", ToastType.ERROR);
          await router.replace("/");
          return Promise.reject();
        }

        return res;
      },
      onSuccess: (data) => {
        const { event, release } = data.getEventAndReleaseForCustomer;
        actions.updateTrackingContext({
          id: event.id,
          name: event.title,
          releaseId: release?.id,
          releaseName: release?.name,
        });
      },
    }
  );

  const { event, release, isAdmin, accessType } =
    data?.getEventAndReleaseForCustomer ?? {};

  return {
    isLoading,
    release,
    isAdmin,
    event,
    error,
    isValidating,
    accessType,
  };
};
